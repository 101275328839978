<script>
var moment = require("moment");
moment.locale("fr");
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Swal from "sweetalert2";
import loaderProcess from "../../../components/widgets/loaderProcess.vue";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import sectionVideoBlock from "../../../components/widgets/card/sectionVideoBlock.vue";
/**
 * Projects-list component
 */
export default {
  page: {
    title: "Projects List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    loaderProcess,
    sectionVideoBlock,
  },

  data() {
    return {
      searchQuery: "",
      progress: null,
      titre1: "",
      process: false,
      updateModal: false,
      idVideos: [],
      oneSection: [],
      titre: "",
      titreSection: "",
      sectionUpdate: [],
      idVideosUpdateSection: [],
      idSec: "",

      programme: {
        titre: "",
        description: "",
        duree: "",
      },
      sections: [],
      modalOk: false,
      categ: {
        titre: "",
        description: "",
      },
      categories: [],
      channels: [],
      moment: moment,
      contenu: "video",
      videos: [],
      programmes: [],
      title: "Liste des sections des vidéos",
      items: [
        {
          text: "Gestion des sections",
          // href: "/projects/sections",
        },
        {
          text: "Sections",
          active: true,
        },
      ],
    };
  },
  methods: {
    showModal() {
      this.modalOk = true;
    },
    createSection() {
      this.process = true;
      this.modalOk = false;

      Api.postFormData(`/streamvod/rest/section/create-section/checking`, {
        titre: this.titre,
        videos_id: this.idVideos,
      })
        .then(() => {
          this.process = false;
          Swal.fire("Succes!", "Section Créée", "success");
          // location.reload()
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },
    getSection() {
      Api.get("/streamvod/rest/section/all-test")
        .then((response) => {
          this.sections = response.data.content;
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },

    getVideos() {
      Api.get("/streamvod/rest/videos/all")
        .then((res) => {
          this.videos = res.data.content;
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {
    this.getSection();
    this.getVideos();
  },
  computed: {
    filteredVideoList() {
      if (!this.searchQuery) {
        return this.videos;
      }
      return this.videos.filter((video) => {
        const fullName = video.title;
        return fullName.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <loader-process :visible="process" :progress="progress" :title="titre1" />
    <div class="row justify-content-end">
      <div class="col-lg-10" style="margin-top: 10px">
        <button
          type="submit"
          class="btn c2play-primary"
          style="position: relative; left: 90%"
          @click="showModal"
        >
          {{ $t("addButton") }}
        </button>
      </div>
    </div>
    <br />
    <div v-for="section in sections" :key="section.id">
      <sectionVideoBlock
        :id="section.id"
        :titre="section.titre"
        :videos="section.videos"
        :allVideo="videos"
      ></sectionVideoBlock>
    </div>
    <!-- end row -->

    <!-- <div class="row">
      <div class="col-12">
        <div class="text-center my-3">
          <a href="javascript:void(0);" class="text-success">
            <i class="bx bx-loader bx-spin font-size-18 align-middle me-2"></i>
            Load more
          </a>
        </div>
      </div>
    </div> -->

    <b-modal
      v-model="modalOk"
      id="modal-xl"
      size="xl"
      :title="$t('pages.sections.formulaire.title')"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col-lg-12">
              <label class="col-form-label">{{
                $t("pages.sections.formulaire.nom.label")
              }}</label>
              <div class="col-md-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  class="form-control"
                  v-model="titre"
                  :placeholder="$t('pages.sections.formulaire.nom.placeholder')"
                />
              </div>
            </div>
          </div>
        </form>
        <div class="row">
          <div class="col-lg-1">
            <br /><br />
            <button @click="createSection" class="btn c2play-primary">
              {{ $t("addButton") }}
            </button>
          </div>
          <div class="col-lg-5"></div>
          <div class="col-lg-6">
            <br /><br />
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Rechercher une vidéo"
                style="border-radius: 8px"
                v-model="searchQuery"
              />
            </div>
          </div>
        </div>

        <br />
        <div class="row">
          <div
            class="col-lg-3"
            v-for="video in filteredVideoList.slice().reverse()"
            :key="video.id"
          >
            <b-form-checkbox
              v-model="idVideos"
              name="checkbox-1"
              :value="video.id"
              unchecked-value="video.id"
            >
              <div>
                {{ video.title }}
              </div>
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
<style>
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 20px;
}

.swiper-pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.swiper-button-next {
  right: 10px;
}

.swiper-button-prev {
  left: 10px;
}
</style>
